.tools-container {
  .heading {
    width: 100%;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
  }

  .sub-heading {
    // font-size: 15px;
    // font-weight: 500;
    text-align: left;
    margin-top: 40px;
  }

  .MuiFormLabel-root {
    font-size: 14px !important;
    padding-bottom: 6px !important;
    font-weight: 600 !important;
  }
  .input-field-container {
    display: flex;
    justify-content: center;
  }
}