body {
    margin: 0;
    // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    //     "Droid Sans", "Helvetica Neue", sans-serif;
    font-family: "DM Sans", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: #2d2d2d;
    outline: 1px solid slategrey;
}

.font-family {
    font-family: "DM Sans", sans-serif !important;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "DM Sans", sans-serif !important;
    margin: 0px;
    padding: 0px;
}
span {
    font-family: "DM Sans", sans-serif !important;
}

div {
    font-family: "DM Sans", sans-serif !important;
}
p {
    font-family: "DM Sans", sans-serif !important;
    margin: 0px;
    padding: 0px;
}

.main-loader {
    height: 100vh !important;
    .Loader__background {
        z-index: 9999 !important;
    }
    .Loader__content {
        height: 100vh;
    }
}

.platform-loader {
    height: 100vh !important;
    .Loader__background {
        // z-index: 9999 !important;
        background-color: white !important;
        z-index: 0 !important;
    }
    // .Loader__content {
    //     height: 100vh;
    // }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.container-input {
    // width: fit-content;
    margin: auto;
    .opt-input-field {
        outline: none;
        margin: auto;
        height: 40px;
        min-width: 35px;
        width: 20% !important;
        color: white;
        font-size: 18px;
        background: transparent;
        border: 2px solid white;
        border-radius: 3px;
    }
}

.login-container {
    // background-image: url(../img/login-bg/bg4.jpg);
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
    min-height: 100vh;
    height: 100%;
    background-color: black;
    position: relative;
    .login-background-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .login-container-grid {
        height: 100%;
        display: flex;
        margin: auto;
        .login-div {
            z-index: 10;
            margin: auto;
            background-color: #292929d1;
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            border-radius: 5px;
            min-height: 400px;
            width: 350px;
            padding: 20px;
            .logo-image-div {
                width: 100%;
                img {
                    max-width: 73%;
                }
            }
            .forgot-password {
                float: right;
                color: #fffffff5;
                cursor: pointer;
                &:hover {
                    color: #fa9595f5;
                }
            }
            .dont-have-account {
                cursor: pointer;
                text-align: center;
                color: #fffffff5;
                &:hover {
                    color: #fa9595f5;
                }
            }
            .MuiOutlinedInput-input {
                color: white !important;
                // font-size: 20px !important;
                // font-family: "DM Sans", sans-serif !important;
            }
            .received-otp-p {
                color: white;
                text-align: center;
            }
            .resend-otp {
                color: white;
                cursor: pointer;
                float: right;
                &:hover {
                    color: #fa9595f5;
                }
            }
        }
    }
}

.MuiOutlinedInput-input {
    font-family: "DM Sans", sans-serif !important;
}

.dashboard-contianer {
    .youtube-large-video {
        height: 400px;
        div {
            height: 100%;
        }
    }
    .youtube-small-video {
        height: 250px;
        div {
            height: 100%;
        }
    }
}

.create-release-container {
    .MuiTabs-indicator {
        background: linear-gradient(
            90deg,
            rgba(19, 153, 121, 1) 0%,
            rgba(18, 180, 161, 1) 45%,
            rgba(5, 150, 179, 1) 100%
        ) !important;
        height: 3px;
        border-radius: 8px 8px 0px 0px;
    }
    .MuiTabs-flexContainer {
        justify-content: center !important;
        border-bottom: 1px solid rgb(214, 214, 214);
        overflow: auto;
    }
    .MuiTab-root {
        width: 20% !important;
    }
    .MuiTab-wrapper {
        align-items: baseline !important;
        text-transform: capitalize;
        font-size: 15px;
    }
    .Mui-selected {
        background: rgb(235, 235, 235);
        border-radius: 5px 5px 0px 0px;
    }
    .releaseinfo-container {
        .MuiFormLabel-root {
            font-size: 14px;
            padding-bottom: 6px;
            font-weight: 600;
        }
        .release-type {
            display: flex;
            justify-content: space-between;
            p {
                border: 1px solid rgb(190, 190, 190);
                border-radius: 5px;
                padding: 8px;
                cursor: pointer;
                color: #4d4d4d;
                font-weight: 500;
                font-size: 16px;
                // height: 40px;
                align-self: center;
                margin: auto;
                &.active-type {
                    background: #0aa3ab;
                    color: white;
                }
            }
        }
        .image-div {
            width: 200px;
            height: 200px;
            margin: auto;
            border-style: groove;
            display: flex;
            img {
                width: 98%;
                height: 98%;
                margin: auto;
                object-fit: cover;
            }
        }
        .change-image {
            margin-top: 11px;
            color: white;
            background: #e42b2b;
            padding: 2px 7px;
            display: inline-flex;
            border-radius: 5px;
            cursor: pointer;
        }
        .css-yk16xz-control {
            background: transparent !important;
        }
        #releaseInfo-file-upload {
            display: none;
        }
        .releaseInfo-file-upload-label {
            color: #929292;
            display: flex;
            width: 100%;
            height: 100%;
            cursor: pointer;
            div {
                margin: auto;
                text-align: center;
                svg {
                    font-size: 70px;
                    color: #b3b3b3;
                }
                p {
                    font-size: 15px;
                    color: #929292;
                    font-weight: 900;
                }
            }
        }
    }
}

.MuiPickersToolbar-toolbar {
    background-color: #11b2a2 !important;
}
.MuiPickersDay-daySelected {
    background-color: #11b2a2 !important;
}

.add-icon-button {
    font-size: 20px;
    margin-left: 5px;
    background: #00a1ff;
    color: white;
    border-radius: 17px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    &.delete {
        background: red !important;
    }
}
.save-icon-button {
    font-size: 28px !important;
    margin-left: 5px;
    margin-top: 5px;
    // background: #00a1ff;
    color: #00a1ff;
    // border-radius: 17px;
    // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    //     0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

.artwork-guidlines {
    font-size: 17px;
    color: #0597b2;
    font-weight: 500;
}

.songinfo-container {
    h3 {
        text-align: center;
    }
    .song-upload-details {
        height: auto;
        border: 1px solid #0597b2;
        border-radius: 5px;
        margin: 15px 0px 20px;
        padding: 10px;
        #song-upload-file {
            display: none;
        }
        .song-upload-file-label {
            color: #929292;
            display: flex;
            width: 100%;
            width: fit-content;
            margin: auto;
            cursor: pointer;
            .upload-span {
                width: 300px;
                .span-1 {
                    background: #0597b2 !important;
                    color: white !important;
                    width: 30%;
                    height: 100% !important;
                    padding: 10px;
                    border-radius: 20px 0px 0px 20px !important;
                }
                .span-2 {
                    padding: 9px;
                    background: #fff !important;
                    border: 1px solid grey;
                    width: 70%;
                    border-left: none;
                    border-color: grey !important;
                    border-radius: 0px 20px 20px 0px !important;
                }
            }
            .upload-progress {
                width: 300px;
                display: flex;
                align-items: center;
                .upload-div {
                    width: 80%;
                    .linearProgress {
                        height: 15px !important;
                        border-radius: 10px !important;
                        .MuiLinearProgress-barColorPrimary {
                            background-color: #0597b2 !important;
                        }
                        &.progress-done {
                            .MuiLinearProgress-barColorPrimary {
                                background-color: #2ac12a !important;
                            }
                        }
                    }
                }
                .upload-p-tag {
                    width: 20%;
                    margin-left: 10px !important;
                    svg {
                        color: #2ac12a;
                    }
                }
            }
        }
        .image-div {
            height: 100px;
            display: flex;
        }
        .delete-svg {
            color: red;
            margin: auto;
            font-size: 28px;
            cursor: pointer;
        }
    }
}

.add-song-details-container {
    label {
        font-size: 14px;
        padding-bottom: 6px;
        font-weight: 600;
    }
    .from-control-radio {
        font-size: 14px;
        font-weight: 500;
    }
}

.MuiDialogContent-root {
    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #2d2d2d;
        outline: 1px solid slategrey;
    }
}

.custom-add-more-button {
    background: #00a1ff;
    color: white;
    border: none;
    outline: none;
    padding: 4px 13px;
    border-radius: 4px;
    margin-top: 5px;
    // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    //     0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: #00a1ff !important;
}

.song-detail-saved {
    border: 1px solid #0597b2;
    border-radius: 5px;
    margin: 15px 0px 20px;
    padding: 10px;
    background: #daebe4;
}

.platform-container {
    // .MuiCheckbox-colorPrimary {
    //     background: #00a1ff !important;
    // }
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #00a1ff !important;
        &:disabled {
            color: #757575 !important;
        }
    }
}

.audio-media-player {
    &:focus {
        outline: none;
    }
}

.catelog-container {
    cursor: pointer;
    .more-stores {
        width: fit-content;
        padding: 5px 10px;
        border-radius: 5px;
        font-weight: 500;
        color: white;
        background: #00a1ff;
        &:hover {
            border: 1px solid #00a1ff;
            padding: 4px 10px;
            background: #fff;
            color: #00a1ff;
        }
    }
    .delete-draft {
        width: fit-content;
        padding: 5px 10px;
        border-radius: 5px;
        font-weight: 500;
        color: white;
        background: #f31b1b;
        &:hover {
            border: 1px solid #f31b1b;
            padding: 4px 10px;
            background: #fff;
            color: #f31b1b;
        }
    }
}

.view-release-container {
    .status-box {
        border-radius: 5px;

        p {
            position: relative;
            color: black;
            font-size: 16px;
            padding-left: 22px;
            svg {
                top: 2px;
                left: 0;
                position: absolute;
                font-size: 16px;
            }
        }
    }
    .catalog-image {
        .image-div {
            width: 220px;
            height: 220px;
            margin: auto;
            // text-align: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .item-container {
        label {
            font-size: 14px;
            padding-bottom: 6px;
            font-weight: 600;
        }
    }
}

.submission-container {
    .image-div {
        width: 200px;
        height: 200px;
        margin: auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.universal-table {
    thead {
        tr {
            th {
                font-weight: 600;
            }
        }
    }
    tbody {
        tr {
            &:last-child {
                td {
                    border-bottom: none !important;
                }
            }
        }
    }
}

.table-pagination {
    display: flex !important;
    justify-content: end !important;
    padding: 20px !important;
}

.textarea-input-field {
    width: 100%;
    border-radius: 4px;
    padding: 10px;
    font-family: "DM sans", sans-serif !important;
    font-size: 15px !important;
    // border-color: rgba(0, 0, 0, 0.23);
    overflow: auto;
    resize: none;
    &::placeholder {
        color: #a3a3a7;
    }

    &:hover {
        border-color: rgba(0, 0, 0, 0.87);
    }

    &:focus {
        outline: none !important;
        border-color: #10baa8 !important;
        border-width: 1px !important;
    }
}

#upload-reason {
    display: none;
}
.lable-upload-reason {
    cursor: pointer;
    font-weight: 500 !important;
    display: block;
    height: 40px;
    padding-bottom: 0px !important;
    div {
        display: flex;
        height: 100%;
        p {
            display: flex;
            align-items: center;
            // text-align: center;
            height: 100%;
            &:first-child {
                width: 25%;
                min-width: 108px;
                background-color: #00a1ff;
                color: white;
                padding: 5px 10px;
                border-radius: 5px 0px 0px 5px;
            }
            &:last-child {
                border: 1px solid #00a1ff;
                border-left: none;
                padding: 5px 10px;
                width: 100%;
                border-radius: 0px 5px 5px 0px;
                span {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.view-tickets-container {
    .box-div {
        label {
            font-weight: 600;
        }
        p {
        }
    }
    .reply-ticket-input {
        height: 100px;
        font-family: "Roboto", sans-serif;
        width: 100%;
        border: 1px solid grey;
        outline: none;
        border-radius: 5px;
        padding: 5px;
        font-size: 15px;
        resize: none;
    }
    .svg-div {
        width: 55px;
        height: 100%;
        margin: auto;
        padding-left: 8px;
        display: flex;
        button {
            border-radius: 30px;
            border: none;
            outline: none;
            height: 40px;
            width: 40px;
            background-color: #1da21d;
            color: white;
            cursor: pointer;
            .svg-send {
                margin-top: 3px;
                margin-left: 2px;
            }
        }
    }
}
.box-grid-container {
    .rightStyle {
        clear: both;
        float: left;
        .inner-div {
            padding: 8px 11px;
            border-radius: 6px;
            width: fit-content;
            font-size: 15px;
            background: #dadada;
            margin-bottom: 8px;
            max-width: 200px;
        }
    }
    .leftStyle {
        clear: both;
        float: left;
        .inner-div {
            padding: 8px 11px;
            border-radius: 6px;
            width: fit-content;
            font-size: 15px;
            background: #93dad9;
            margin-bottom: 8px;
            max-width: 200px;
        }
    }
}

.report-container {
    .start-heading {
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 3px;
    }
    .selectedDate {
        margin-top: 5px;
        text-align: center;
        font-size: 17px;
        span {
            font-weight: 600;
        }
    }
}

.a-tag-term-conditions {
    color: #3dc4ce;
    font-weight: 500;
}

.container-item-user {
    padding: 15px;
    label {
        font-weight: 600;
        font-size: 16px;
    }
    P {
        font-size: 16px;
        position: relative;
        svg {
            position: absolute;
            font-size: 18px;
            margin-top: 1px;
            margin-left: 8px;
            color: #c14f18;
            cursor: pointer;
        }
    }
}

.album-artwork-catalog {
    width: 60px;
    height: 60px;
    margin: auto;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.required-span {
    color: red;
    margin-left: 3px;
    font-size: 15px;
}

.request-payout-Button {
    background-color: green !important;
    color: white !important;
}

.revoked-user-container {
    p {
        &:first-child {
            font-size: 16px !important;
        }
    }
}

.pending-report {
    border: 1px solid grey;
    // padding: 4px 18px;
    border-radius: 5px;
    display: block;
    width: 80px;
    font-weight: 600;
    text-align: center;
}
.received-report {
    width: 80px;
    text-align: center;
    display: block;
    border: 1px solid green;
    // padding: 4px 18px;
    border-radius: 5px;
    font-weight: 600;
    color: green;
}

.edit-re-submit {
    float: right;
    background: green !important;
    color: white !important;
    margin-top: 16px !important;
}

.social-media-icons {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0px 25px;
    // background: white;
    p {
        color: white;
        text-align: center;
    }
    ul {
        display: flex;
        list-style-type: none;
        justify-content: space-between;
        padding: 0;
        margin: 5px 0px;
        li {
            svg {
                color: white;
            }
        }
    }
}

.ticket-info-tag {
    background-color: #6f6f6f;
    color: #ffffff;
    padding: 6px;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    p {
        margin: auto;
        display: inline-flex;
        font-size: 16px;
        svg {
            font-size: 20px;
            margin-right: 5px;
        }
    }
}

.welcome-by-ref {
    margin-bottom: 10px;
    p {
        text-align: center;
        color: white;
        font-size: 25px;
        // font-style: italic;
    }
}

.bank-details-modal {
    label {
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 5px;
    }
}

.request-payout-p-tag {
    float: right;
    margin-bottom: 15px;
    margin-right: 20px;
    color: #027b00;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid green;
    padding: 4px 15px;
    border-radius: 4px;
    &:hover {
        background-color: #027b00;
        color: white !important;
    }
}

.notification-container {
    position: absolute;
    z-index: 1;
    background: #2d2d2d;
    top: 60px;
    right: -8px;
    padding: 10px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    width: 350px;
    height: 400px;
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 12px;
        // background: white;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px #0caeac;
        background: #0caeab56;
    }
}

.notification-popover {
    height: 100%;
    .loader-container {
        margin: auto;
        width: 100%;
        height: 15%;
        padding: 15px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content-container {
        height: 100%;
        width: 100%;
        margin: auto;
        .no-notification {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .content-div {
            border-bottom: 1px solid grey;
            padding: 10px 0px;
            p {
                display: flex;
                flex-wrap: wrap;
                span {
                    // margin-right: 5px;
                }
                a {
                    color: #0caeac;
                }
            }
            .date-time {
                color: grey;
                font-size: 12px;
                display: block;
                text-align: end;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

.server-maintenance {
    display: flex;
    height: 100%;
    p {
        margin: auto;
        font-weight: 600;
        font-size: 22px;
        max-width: 600px;
        text-align: center;
    }
}

.ticket-reply-created {
    position: absolute;
    color: grey;
    width: 160px;
    top: -18px;
    font-size: 12px;
    &.right {
        right: -40px;
    }
    &.left {
        left: 0;
    }
}

.custom-file-input-container {
    display: flex;
    align-items: center;
    gap: 10px; // Add some spacing between the button and the selected file text
  }
  
  .custom-file-input-button {
    background-color: #3498db;
    color: #fff;
    padding: 11px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .custom-file-input-selected {
    display: flex;
    align-items: center;
    flex: 1;
    text-align: right; // Align the selected file text to the right side
  }
  
  .custom-file-input-selected span {
    font-size: 14px; // Adjust the font size as needed
  }
  
  .checkmark-icon {
    width: 20px; // Adjust the icon size as needed
    height: 20px;
    color: #4caf50; // Set the color of the icon
    margin-left: 5px; // Add some spacing between the selected file text and the icon
  }